<template>
  <div>
    <div class="widget-px widget-pt">
      <div class="widget-form-group">
        <select name="payment" id class="widget-form-control widget-form-select" v-model="form.selectPayment"
          @change="$emit('onChangePayment', form.selectPayment)">
          <option @click="
            $gtag(
              'send',
              'event',
              'Forma de pagamento Cartão de crédito (Simplificada)',
              'Forma de pagamento Cartão de crédito',
              'Cartão de crédito'
            )
            " value="card" v-if="this.paymentMethodList.includes(this.paymentMethod.CreditCard)
            ">
            Cartão de crédito
          </option>
          <option @click="
            $gtag(
              'send',
              'event',
              'Forma de pagamento Boleto (Simplificada)',
              'Forma de pagamento Boleto',
              'Boleto'
            )
            " value="barcode" v-if="this.paymentMethodList.includes(this.paymentMethod.Ticket)">
            Boleto bancário
          </option>
          <option @click="
            $gtag(
              'send',
              'event',
              'Forma de pagamento Débito em Conta (Simplificada)',
              'Forma de pagamento Débito em Conta',
              'Débito em Conta'
            )
            " value="accountDebit" v-if="this.paymentMethodList.includes(this.paymentMethod.AccountDebit)
            ">
            Debito em conta
          </option>
          <option value="pix" v-if="this.paymentMethodList.includes(this.paymentMethod.Pix)" @click="
            $gtag(
              'send',
              'event',
              'Forma de pagamento Pix (Simplificada)',
              'Forma de pagamento Pix',
              'Pix'
            )
            ">
            PIX
          </option>
        </select>
        <span class="hint"></span>
      </div>
      <div class="widget-form-group" @change="cartTaxMethodPayment(), verifydonationRecurrencePeriod()">
        <InputSelect ref="recurrencePeriodInput" v-model="cart.donationRecurrencePeriodId" :items="recurrencePeriodList"
          valueAttribute="id" textAttribute="name" invalidInputMessage="Escolha a recorrência da sua doação"
          @input="gethowMany()" :forceSelected="mainRecurrencyToSelect"></InputSelect>
      </div>
      <div v-show="false">
        <span class="color-theme-texto">Valor mínimo da doação:</span>
        <span class="color-theme-texto" style="float: right; font-weight: bold">{{ minimumPeriodDonationValue |
          FormatMoney }}</span>
      </div>
      <div v-if="campaignLandingPageConfig.campaign.taxOwner == taxOwners.Person &&
        form.selectPayment != 'accountDebit'
      ">
        <span v-if="cart.taxValue > 0" v-show="form.selectPayment === 'card'" style="color: red">Taxa do cartão de
          crédito:</span>
        <span v-if="cart.taxValue > 0" style="color: red" v-show="form.selectPayment === 'barcode'">Taxa do
          boleto:</span>
        <span v-if="cart.taxValue > 0" style="color: red" v-show="form.selectPayment === 'pix'">Taxa do pix:</span>
        <span v-if="cart.taxValue > 0" style="float: right; font-weight: bold; color: red">{{
          cart.taxValue | FormatMoney
          }}</span>
      </div>
      <div>
        <span class="color-theme-texto">Valor Total:</span>
        <span :key="`totalvalue-${gethowManyInput}`" class="color-theme-texto"
          style="float: right; font-weight: bold">{{
            (totalCartValue + cart.taxValue) | FormatMoney }}</span>
      </div>
      <div v-if="verifyParcelamentPaymentMethod()" :key="`parcelament-${gethowManyInput}`">
        <div v-if="listHowManyOptions.length > 0">
          <span class="color-theme-texto">Parcelar:</span>
          <span class="color-theme-texto" style="float: right; font-weight: bold">Valor mínimo da parcela:
            {{
              getPaymentMethodMinimumParcelamentValue
              | FormatMoney
            }}</span>
          <div>
            <InputSelect :items="listHowManyOptions" :useAnotherDefaultValue="true" :unselectedValue="0"
              unselectedText="Sem parcelamento" v-model="cart.creditCardParcelament" valueAttribute="quantidade"
              textAttribute="name" style="width: 70%" :key="gethowManyInput" @input="getDayOfMonthInput"></InputSelect>
          </div>
        </div>
        <div :key="dayOfMonthInput" class="widget-form-group">
          <InputSelect v-if="showInputPaymentDay()" :key="`day-${gethowManyInput}`" ref="dayOfMonthInput"
            placeholderText="Data de vencimento" textLabel="Data de vencimento:" textAttribute="name"
            valueAttribute="id" :items="daysOfMonth" v-model="form.ticketInfo.dayOfMonth"
            invalidInputMessage="Selecione o dia para débito" style="width: 40%" />
        </div>
      </div>
      <hr class="widget-divider" />
      <div class="widget-form-group" v-if="campaignLandingPageConfig.campaign.campaignInputInformation &&
        campaignLandingPageConfig.campaign.campaignInputInformation
          .simplifiedCheckoutInformMemberCode
      ">
        <div class="widget-form-control">
          <input ref="memberCodeInput" type="text" placeholder="Código de Indicação - Opcional"
            v-model="form.memberCode" @blur="
              validate('memberCode');
            findPersonByCodeIndication();
            " />
          <button class="btn-find-person">
            <i class="fal fa-search"></i>
          </button>
        </div>
        <span class="hint">Por favor, digite o seu código de membro</span>
        <div>{{ personIndicationName }}</div>
      </div>
      <div class="widget-form-group" ref="cpfInput">
        <div class="widget-form-control">
          <input type="text" :placeholder="campaignLandingPageConfig.campaign.permitCnpjDonation
            ? 'CPF/CNPJ'
            : 'CPF'
            " v-mask="campaignLandingPageConfig.campaign.permitCnpjDonation
              ? ['###.###.###-##', '##.###.###/####-##']
              : ['###.###.###-##']
              " v-model="form.cpf" @blur="validate('cpf')" autocomplete="off" />
          <i class="widget-listitem-icon fal fa-id-card"></i>
        </div>
        <span class="hint">Por favor, digite o
          {{
            campaignLandingPageConfig.campaign.permitCnpjDonation
              ? "CPF/CNPJ"
              : "CPF"
          }}</span>
      </div>
      <div class="widget-form-group" v-if="!logged && form.cpf.length > 14">
        <div class="widget-form-control">
          <input ref="socialreasonInput" type="text" placeholder="Razão Social" v-model="form.socialReason"
            @input="nameToUpperCase()" @blur="validate('socialReason')" />
          <i class="widget-listitem-icon fal fa-user"></i>
        </div>
        <span class="hint">Por favor, digite a Razão Social</span>
      </div>
      <div class="widget-form-group" ref="nameInput" v-if="!logged">
        <div class="widget-form-control">
          <input  type="text" :placeholder="form.cpf.length > 14 ? 'Nome do responsável' : 'Nome completo'
            " v-model="form.name" @input="nameToUpperCase()" @blur="validate('name')" />
          <i class="widget-listitem-icon fal fa-user"></i>
        </div>
        <span class="hint">Por favor, digite o seu nome</span>
      </div>
      <div class="widget-form-group" ref="emailInput" v-if="!logged">
        <div class="widget-form-control">
          <input type="text" placeholder="E-mail" v-model="form.email" @blur="validate('email')" autocomplete="off" />
          <i class="widget-listitem-icon fal fa-envelope"></i>
        </div>
        <span class="hint">Por favor, digite o seu email</span>
      </div>
      <template v-for="question in questions">
        <div class="widget-form-group" :ref="`response${question.responseNumber}Input`">
          <div class="widget-form-control ">
            <select :name="`response${question.responseNumber}`" @change="validate('cartInformation.response' + question.responseNumber)" class="widget-form-select" v-model="form.cartInformation[`response${question.responseNumber}`]">
              <option value="" disabled selected>{{ question.label }}</option>
              <option v-for="option in questionOptions(question)" :key="option.id" :value="option.description" v-text="option.description"></option>
            </select>
          </div>
          <span class="hint">{{ question.label }}</span>
        </div>
      </template>
      <template v-if="campaignLandingPageConfig.campaignId == 648">
        <div class="widget-form-group" ref="companyNameInput">
          <div class="widget-form-control ">
            <select name="companyName" id class="widget-form-select" v-model="form.companyName" @change="validate('companyName')">
              <option value="" disabled selected>Selecione a empresa</option>
              <option v-for="company in companiesOptionsIv" :key="company" :value="company" v-text="company"></option>
            </select>
          </div>
          <span class="hint">Selecione a empresa</span>
        </div>
      </template>
      <template v-else-if="campaignLandingPageConfig.campaignId == 651">
        <div class="widget-form-group" ref="companyNameInput">
          <div class="">
            <select name="companyName" id class="widget-form-select"
              v-model="form.companyName" @change="validate('companyName')">
              <option value="" disabled selected>Selecione a Empresa</option>
              <option v-for="company in companiesOptionsClaro" :key="company" :value="company" v-text="company"></option>
            </select>
          </div>
          <span class="hint">Selecione a Empresa</span>
        </div>
      </template>
      <template v-else-if="campaignLandingPageConfig.campaignId == 612">
        <div class="widget-form-group" ref="companyNameInput">
          <div class="widget-form-control ">
            <select name="companyName" id class="widget-form-control widget-form-select"
              v-model="form.companyName" @change="validate('companyName')">
              <option value="" disabled selected>Escolha o dia do seu baile</option>
              <option v-for="option in optionsFlipei" :key="option" :value="option" v-text="option"></option>
            </select>
          </div>
          <span class="hint">Escolha o dia do seu baile</span>
        </div>
      </template>
      <template v-else>
        <div class="widget-form-group" ref="companyNameInput" v-if="campaignLandingPageConfig.campaign.campaignInputInformation &&
          campaignLandingPageConfig.campaign.campaignInputInformation
            .simplifiedCheckoutInformCompany
        ">
          <div class="widget-form-control">
            <input type="text" placeholder="Empresa" v-model="form.companyName" @blur="validate('companyName')" />
            <i class="widget-listitem-icon fal fa-industry"></i>
          </div>
          <span class="hint">Por favor, digite a empresa</span>
        </div>
      </template>
      <div class="widget-form-group" ref="departamentNameInput" v-if="campaignLandingPageConfig.campaign.campaignInputInformation &&
        campaignLandingPageConfig.campaign.campaignInputInformation
          .simplifiedCheckoutInformDepartment
      ">
        <div v-if="campaignLandingPageConfig.campaign.id == 416">
          <div class="widget-form-control">
            <input type="text" placeholder="Em qual Bob's você está?" v-model="form.departamentName"
              @blur="validate('departamentName')" />
            <i class="widget-listitem-icon fal fa-building"></i>
          </div>
          <span class="hint">Por favor, digite em qual Bob's você está?</span>
        </div>
        <div v-else-if="campaignLandingPageConfig.campaign.id == 484">
          <div class="widget-form-control">
            <input type="text" placeholder="Indique sua sucursal" v-model="form.departamentName"
              @blur="validate('departamentName')" />
            <i class="widget-listitem-icon fal fa-building"></i>
          </div>
          <span class="hint">Por favor, digite sua sucursal</span>
        </div>
        <div v-else-if="campaignLandingPageConfig.campaign.id == 651">
          <div class="widget-form-group">
            <select name="companyName"  id class="widget-form-select"
              v-model="form.departamentName" @change="validate('departamentName')">
              <option value="" disabled selected>Selecione a Regional</option>
              <option v-for="regional in departamentOptionsClaro" :key="regional" :value="regional" v-text="regional">
              </option>
            </select>
            <span class="hint">Selecione sua regional</span>
          </div>
        </div>
        <div v-else>
          <div class="widget-form-control">
            <input type="text" placeholder="Departamento" v-model="form.departamentName"
              @blur="validate('departamentName')" />
            <i class="widget-listitem-icon fal fa-building"></i>
          </div>
          <span class="hint">Por favor, digite o departamento</span>
        </div>
      </div>
      <div class="widget-form-group" ref="postalCodeInput" v-if="campaignLandingPageConfig.campaign.campaignInputInformation &&
        campaignLandingPageConfig.campaign.campaignInputInformation
          .simplifiedCheckoutInformAddress
      ">
        <div class="widget-form-control">
          <the-mask type="tel" placeholder="CEP Residencial" autocomplete="billing postal-code"
            v-model="form.address.postalCode" mask="#####-###" @input="searchCEP(); validate('address.postalCode')" />
          <i class="widget-listitem-icon fal fa-map-marker"></i>
        </div>
        <span class="hint">Por favor, digite o CEP</span>
      </div>
      <!-- NOTE Address -->
      <div v-if="form.address.postalCode.length > 0">
        <div class="widget-form-group" ref="streetInput">
          <div class="widget-form-control">
            <input  type="text" placeholder="Logradouro" autocomplete="billing street-address"
              place-holder="ex: Av. Paulista" v-model="form.address.street" :labelCharLimit="200" @blur="validate('address.street')"/>
            <i class="widget-listitem-icon fal fa-road"></i>
          </div>
          <span class="hint">Por favor, digite o logradouro</span>
        </div>
        <div class="widget-form-group"  ref="numberInput">
          <div class="widget-form-control">
            <input required type="number" @blur="clearNumber(); validate('address.number')" placeholder="Número" autocomplete="off"
              place-holder="ex: 123" v-model="form.address.number" invalidInputMessage="Preencha o número"/>
            <i class="widget-listitem-icon fal fa-list-ol"></i>
          </div>
          <span class="hint">Por favor, digite o número</span>
        </div>
        <div class="widget-form-group">
          <div class="widget-form-control">
            <input type="text" :required="false" autocomplete="off" placeholder="Complemento"
              v-model="form.address.complement" :max="50" />
            <i class="widget-listitem-icon fal fa-address-book"></i>
          </div>
          <span class="hint"></span>
        </div>
        <div class="widget-form-group" ref="neighborhoodInput">
          <div class="widget-form-control">
            <input  type="text" placeholder="Bairro" v-model="form.address.neighborhood" @blur="validate('address.neighborhood')"
              :max="100" />
            <i class="widget-listitem-icon fal fa-address-book"></i>
          </div>
          <span class="hint">Preencha o bairro</span>
        </div>
        <div class="widget-form-group" ref="cityInput">
          <div class="widget-form-control">
            <input  required type="text" placeholder="Cidade" autocomplete="city"
              v-model="form.address.city" :max="100" @blur="validate('address.city')"/>
            <i class="widget-listitem-icon fal fa-map-marker"></i>
          </div>
          <span class="hint">Preencha a cidade</span>
        </div>
      </div>
      <!-- NOTE State -->
      <div class="widget-form-group" ref="stateInput" v-if="campaignLandingPageConfig.campaign.campaignInputInformation &&
        campaignLandingPageConfig.campaign.campaignInputInformation
          .simplifiedCheckoutInformState
      ">
        <div class="widget-form-control">
            <select name="state" id class="widget-form-select"
              v-model="form.address.state" @change="validate('address.state')" placeholder="Estado">
              <option value="" disabled selected>Selecione o estado</option>
              <option v-for="state in stateItems" :key="state.name" :value="state.name" v-text="state.name">
              </option>
            </select>
        </div>
        <span class="hint">Por favor, informe o estado</span>
      </div>

      <div class="widget-form-group" ref="cityInput" v-if="campaignLandingPageConfig.campaign.campaignInputInformation &&
        campaignLandingPageConfig.campaign.campaignInputInformation
          .simplifiedCheckoutInformCityWithState">
        <div class="">
          <FindCity @city-selected="selectCity" />
        </div>
        <span class="hint">Por favor, informe a cidade</span>
      </div>

      <div class="widget-form-group" ref="phoneInput" v-if="campaignLandingPageConfig.campaign.campaignInputInformation &&
        campaignLandingPageConfig.campaign.campaignInputInformation
          .simplifiedCheckoutInformPhone
      ">
        <div class="widget-form-control">
          <InputText type="tel" place-holder="Telefone: (00) 00000-0000" v-model="form.phone"
            :mask="['(##) ####-####', '(##) #####-####']" autocomplete="tel" @input="validate('phone')"/>
        </div>
        <span class="hint">Por favor, digite o telefone</span>
      </div>
      <div class="widget-form-group" ref="alternativePhoneInput" v-if="campaignLandingPageConfig.campaign.campaignInputInformation &&
        campaignLandingPageConfig.campaign.campaignInputInformation
          .simplifiedCheckoutInformAlternativePhone
      ">
        <label>Telefone alternativo</label>
        <div class="widget-form-control">
          <InputText type="tel" place-holder="(00) 00000-0000" v-model="form.alternativePhone"
            :mask="['(##) ####-####', '(##) #####-####']" autocomplete="tel" />
        </div>
        <span class="hint">Por favor, digite o telefone alternativo</span>
      </div>
      <div class="widget-form-group" ref="genderInput" v-if="campaignLandingPageConfig.campaign.campaignInputInformation &&
        campaignLandingPageConfig.campaign.campaignInputInformation
          .simplifiedCheckoutInformGender
      ">
        <label>Gênero</label>
        <div class="widget-form-control" ref="genderInput">
          <InputSelect placeholder="Gênero"  :items="gender" v-model="form.gender" textAttribute="text"
            valueAttribute="value" @blur="validate('gender')"></InputSelect>
        </div>
        <span class="hint">Por favor, selecione um gênero</span>
      </div>
      <div class="widget-form-group" ref="birthDateInput" v-if="campaignLandingPageConfig.campaign.campaignInputInformation &&
        campaignLandingPageConfig.campaign.campaignInputInformation
          .simplifiedCheckoutInformBirthDate
      ">
        <label>Data de Nascimento</label>
        <div class="widget-form-control">
          <InputDate type="text" placeholder="Data de Nascimento" v-model="form.birthDate"
            @input="validate('birthDate')" />
        </div>
        <span class="hint">Por favor, insira uma data válida</span>
      </div>
    </div>

    <div id="bar-code" v-show="form.selectPayment === 'barcode'">
      <div class="widget-listitem" v-show="!disabledTerm">
        <div class="widget-listitem">
          <input type="checkbox" v-model="acceptConsentTerm" class="mr-2" :disabled="disabledTerm" />
          <div>
            Declaro que li e concordo com o
            <u>
              <a @click="openModalConsentTerm()">Termo de anuência</a>
            </u>
          </div>
        </div>
      </div>
      <div class="widget-listitem">
        <button class="widget-btn" :disabled="blockButton"
          @click="checkout(); $gtag('send', 'event', 'Forma de pagamento Boleto', 'Forma de pagamento Boleto', 'Boleto');">Gerar
          boleto</button>
      </div>
    </div>
    <div id="pix-code" v-show="form.selectPayment === 'pix'">
      <div class="widget-listitem" v-show="!disabledTerm">
        <div class="widget-listitem">
          <input type="checkbox" v-model="acceptConsentTerm" class="mr-2" :disabled="disabledTerm" />
          <div>
            Declaro que li e concordo com o
            <u>
              <a @click="openModalConsentTerm()">Termo de anuência</a>
            </u>
          </div>
        </div>
      </div>
      <div class="widget-listitem">
        <button class="widget-btn" :disabled="blockButton"
          @click="checkout(); $gtag('send', 'event', 'Forma de pagamento Pix', 'Forma de pagamento Pix', 'Pix');">
          Gerar dados para o PIX
        </button>
      </div>
    </div>
    <div id="credit-card" v-show="form.selectPayment === 'card'">
      <div class="widget-px">
        <div class="widget-form-group" ref="cardNumberInput">
          <div class="widget-flex">
            <div class="widget-form-control">
              <input type="text" placeholder="Número do cartão" v-mask="'#### #### #### ####'" v-model="form.cardNumber"
                @blur="
                  validate('cardNumber');
                getCardFlag();
                " />
              <i class="widget-listitem-icon fal fa-credit-card"></i>
            </div>
            <div class="flag-box" :class="{ active: flag != '' }">
              <img :src="`/images/flags/${flag}.svg`" alt />
            </div>
          </div>
          <span class="hint">Por favor, digite o número do cartão</span>
        </div>

        <div class="widget-form-group" ref="cardNameInput">
          <div class="widget-form-control">
            <input type="text" placeholder="Nome no cartão" v-model="form.cardName" @input="nameToUpperCase()"
              @blur="validate('cardName')" />
            <i class="widget-listitem-icon fal fa-user"></i>
          </div>
          <span class="hint">Por favor, digite o nome no cartão</span>
        </div>

        <div class="widget-form-group" ref="expirationDateInput">
          <div class="widget-form-control">
            <input type="text" placeholder="Data de validade" v-mask="'##/##'" v-model="form.expirationDate"
              @blur="validate('expirationDate')" />
            <i class="widget-listitem-icon fal fa-calendar"></i>
          </div>
          <span class="hint">Por favor, digite a Data de validade</span>
        </div>
        <div class="widget-form-group widget-pb-0" ref="cvvInput">
          <div class="widget-form-control">
            <input type="text" placeholder="CVV" v-mask="'####'" v-model="form.cvv" @blur="validate('cvv')" />
            <i class="widget-listitem-icon fal fa-credit-card-front"></i>
          </div>
          <span class="hint">Por favor, digite o CVV</span>
        </div>
      </div>
      <div class="widget-listitem" v-show="!disabledTerm">
        <input type="checkbox" v-model="acceptConsentTerm" class="mr-2" :disabled="disabledTerm" />
        <div>
          Declaro que li e concordo com o
          <u>
            <a @click="openModalConsentTerm()">Termo de anuência</a>
          </u>
        </div>
      </div>
      <div class="widget-listitem">
        <button class="widget-btn" :disabled="blockButton"
          @click="checkout(); $gtag('send', 'event', 'Forma de pagamento Cartao de credito', 'Forma de pagamento Cartao de creditox', 'Cartao de credito');">Concluir
          doação</button>
      </div>
    </div>
    <div id="account-debit" v-show="form.selectPayment === 'accountDebit'">
      <div class="widget-px">
        <div class="widget-form-group">
          <InputText ref="holderNameInput" type="text" place-holder="Nome completo do titular"
            v-model="form.debitAccountInfo.holder.name" invalidInputMessage="Preencha o nome do titular"
            @input="nameToUpperCase()" class="widget-form-control" />
        </div>
        <!-- <div class="widget-form-group">
            <InputText
              ref="holderCPFInput"
              type="tel"
              textLabel="CPF"
              place-holder="000.000.000-00"
              v-model="form.cpf"
              mask="###.###.###-##"
              invalidInputMessage="Preencha o CPF do títular"
              class="widget-form-control"
            />
        </div>-->
        <div class="widget-form-group">
          <InputSelect ref="paymentCompanyCodeInput" placeholderText="Banco" textAttribute="name" valueAttribute="code"
            :items="debitAccountBanks" v-model="form.debitAccountInfo.paymentCompanyCode"
            invalidInputMessage="Selecione um banco" />
        </div>
        <div class="widget-form-group">
          <InputSelect ref="paymentCompanyCodeInput" placeholderText="Dia para o débito" textAttribute="name"
            valueAttribute="id" :items="daysOfMonth" v-model="form.debitAccountInfo.dayOfMonth"
            invalidInputMessage="Selecione o dia para débito" />
        </div>
        <div class="widget-form-group">
          <InputText ref="bankBranchInput" type="tel" place-holder="Agência" v-model="form.debitAccountInfo.bankBranch"
            invalidInputMessage="Preencha com a agência" class="widget-form-control" />
        </div>
        <div class="widget-form-group">
          <InputText ref="bankAccountInput" type="tel" place-holder="Conta" v-model="form.debitAccountInfo.bankAccount"
            invalidInputMessage="Preencha com a conta" class="widget-form-control" />
        </div>
        <div class="widget-form-group">
          <InputText ref="bankAccountDigitInput" type="tel" place-holder="Dígito"
            v-model="form.debitAccountInfo.bankAccountDigit" invalidInputMessage="Dígito da conta"
            class="widget-form-control" />
        </div>
        <div class="widget-listitem" v-show="!disabledTerm">
          <input type="checkbox" v-model="acceptConsentTerm" class="mr-2" :disabled="disabledTerm" />
          <div>
            Declaro que li e concordo com o
            <u>
              <a @click="openModalConsentTerm()">Termo de anuência</a>
            </u>
          </div>
        </div>
        <div class="widget-listitem">
          <button class="widget-btn" :disabled="blockButton"
            @click="checkout(); $gtag('send', 'event', 'Forma de pagamento Débito em conta', 'Forma de pagamento Débito em conta', 'Débito em conta')">Concluir
            doação</button>
        </div>
      </div>
    </div>
    <div>
      <!-- <Recaptcha
        ref="recaptchaSimplified"
        :key="1"
        @recaptchaSuccess="recaptchaSuccess"
      /> -->
      <ModalPix ref="modalPix" @payed="finishPix" />
      <ConsentTerm ref="consertTermComponent" :cart="cart" coin="BRL"
        :campaignLandingPageConfig="campaignLandingPageConfig" v-model="showConsentTerm"
        :recurrencePeriodList="recurrencePeriodList" :totalPrice="totalCartValue" :taxValue="cartTaxMethodPayment()"
        :parcelament="null" :entityName="campaignLandingPageConfig.campaign.company.entity.name"
        :entityCNPJ="campaignLandingPageConfig.campaign.company.entity.cnpj" :personProp="person"
        @close="showConsentTerm = false" @accept="
          acceptConsentTerm = true;
        showConsentTerm = false;
        "></ConsentTerm>
    </div>
  </div>
</template>

<script>
import ConsentTerm from "@/components/ConsentTerm.vue";
import FindCity from "@/components/FindCity.vue";
import CheckoutRequest from "@/scripts/models/checkoutRequest.model";
import Cart from "@/scripts/models/cart.model";
import CreditCardCartDetails from "@/scripts/models/creditCardCartDetails.model";
import CampaignService from "@/scripts/services/campaign.service";
import CheckoutSimplifiedQuestionService from "@/scripts/services/checkoutSimplifiedQuestion.service";
import CartService from "@/scripts/services/cart.service";
import PaymentMethod from "@/scripts/models/enums/paymentMethod.enum";
import PaymentMethodProvider from "@/scripts/models/enums/paymentMethodProvider.enum";
import { TaxOwners } from "@/scripts/models/enums/taxOwners.enum";
import DonationRecurrencePeriod from "@/scripts/models/enums/donationRecurrencePeriod.enum";
import { Routes } from "@/scripts/models/enums/routes.enum";
import UserService from "@/scripts/services/user.service";
import PersonService from "@/scripts/services/person.service";
import Person from "@/scripts/models/person.model";
import CartItem from "@/scripts/models/cartItem.model";
import Project from "@/scripts/models/project.model";
import { mapGetters } from "vuex";
import CommonHelper from "@/scripts/helpers/common.helper";
import LocationService from "@/scripts/services/location.service.js";
import ModalPix from "@/components/ModalPix.vue";
import CartStatus from "@/scripts/models/enums/cartStatus.enum";
import ReceiverParcelamentService from "@/scripts/services/receiverParcelament.service.js";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import { CampaignPhases, CampaignPhasesText } from "@/scripts/models/enums/campaignPhases.enum";

export default {
  components: {
    ConsentTerm,
    ModalPix,
    FindCity,
  },
  props: {
    payment: {
      type: String,
      default: "barcode",
    },
    fields: Array,
    cartItem: {
      type: Object,
      default() {
        var cartitem = new CartItem();
        cartitem.project = new Project();
        return cartitem;
      },
    },
    campaignLandingPageConfig: Object,
    project: Object,
  },
  data() {
    return {
      formatterHelper: new FormatterHelper(),
      flag: "",
      siteKey: "",
      showConsentTerm: false,
      acceptConsentTerm: false,
      tokenRecaptcha: "",
      locationService: new LocationService(),
      checkoutSimplifiedQuestionService: new CheckoutSimplifiedQuestionService(),
      PaymentMethodProvider: PaymentMethodProvider,
      form: {
        memberCode: "",
        recorrencia: "1",
        selectPayment: "card",
        cardNumber: "",
        cardName: "",
        cpf: "",
        expirationDate: "",
        cvv: "",
        name: "",
        email: "",
        companyName: "",
        departamentName: "",
        address: {
          postalCode: "",
          street: "",
          number: "",
          complement: "",
          neighborhood: "",          
          city: "",
          state: "",
        },
        phone: "",
        gender: "",
        birthDate: "",
        debitAccountInfo: {
          holder: {
            name: "",
            cpf: "",
          },
          billingAddress: {
            country: "br",
          },
          paymentCompanyCode: "",
          dayOfMonth: "",
          bankBranch: "",
          bankAccount: "",
          bankAccountDigit: "",
        },
        ticketInfo: {
          dayOfMonth: "",
        },
        pixInfo: {
          dayOfMonth: "",
        },
        cartInformation: {
          response1: "",
          response2: "",
          response3: "",
        }
      },
      gender: [
        { text: "Feminino", value: "F" },
        { text: "Masculino", value: "M" },
        { text: "Outros", value: "O" },
        { text: "Não informar", value: "-" },
      ],
      daysOfMonth: [],
      stateItems: [],
      debitAccountBanks: null,
      taxOwners: null,
      cart: new Cart(),
      cartService: null,
      addNewUser: true,
      routes: null,
      configManager: null,
      personService: new PersonService(),
      campaignService: new CampaignService(),
      userService: new UserService(),
      recurrencePeriodList: [],
      person: null,
      CommonHelper: CommonHelper,
      personIndicationName: "",
      finishedCart: new Cart(),
      paymentMethodList: [],
      paymentMethod: PaymentMethod,
      ReceiverParcelamentService: new ReceiverParcelamentService(),
      receiverParcelament: [],
      howMany: [
        {
          quantidade: 2,
          name: "2x",
        },
        {
          quantidade: 3,
          name: "3x",
        },
        {
          quantidade: 4,
          name: "4x",
        },
        {
          quantidade: 5,
          name: "5x",
        },
        {
          quantidade: 6,
          name: "6x",
        },
        {
          quantidade: 7,
          name: "7x",
        },
        {
          quantidade: 8,
          name: "8x",
        },
        {
          quantidade: 9,
          name: "9x",
        },
        {
          quantidade: 10,
          name: "10x",
        },
        {
          quantidade: 11,
          name: "11x",
        },
        {
          quantidade: 12,
          name: "12x",
        },
      ],
      donationRecurrencePeriod: 0,
      gethowManyInput: 0,
      dayOfMonthInput: 0,
      blockButton: false,
      campaignPhases: CampaignPhases,
      parcelamentMethod: null,
      listHowManyOptions: [],
      mainRecurrencyToSelect: 'Recorrência Mensal',
      questions: [],
      cities: [],
      citiesNames: [],
    };
  },
  watch: {
    payment: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.changePayment(newValue);
        this.gethowMany();
      },
    },
    logged: function (oldValue, newValue) {
      if (!oldValue && newValue) {
        this.$router.go(0);
      }
    },
    "cartItem.itemValue": {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.sumTotalCartValue();
        this.gethowMany();
        this.resetCreditCardParcelament();
      },
    },
  },
  computed: {
    ...mapGetters(["logged"]),
    companiesOptionsIv() {
      return [
        "Votorantim/VSA/COE/IV/Reservas",
        "HEJOASSU",
        "Votorantim Cimentos",
        "Banco BV",
        "СВА",
        "Auren",
        "Nexa",
        "Citrosuco",
        "Altre",
        "23S Capital",
        "Grupo Hypera Pharma",
        "CCR",
        "Outros"
      ]
    },
    companiesOptionsClaro() {
      return [
        "BCC",
        "Claro",
        "iMúsica",
        "Global Hitss",
        "Outros"
      ]
    },
    departamentOptionsClaro() {
      return [
        "Regional Bahia/Sergipe",
        "Regional Centro-Oeste",
        "Regional Minas Gerais",
        "Regional Nordeste",
        "Regional Norte",
        "Regional Paraná/Santa Catarina",
        "Regional Rio de Janeiro/Espírito Santo",
        "Regional Rio Grande do Sul",
        "Regional São Paulo Capital",
        "Regional São Paulo Interior",
        "Suporte ao Negócio",
        "Outros"
      ]
    },
    optionsFlipei() {
      return [
        "Ingresso para sexta-feira (02/08)",
        "Ingresso para sábado (03/08)",
        "Passe Livre - cota exclusiva do apoio R$220"
      ]
    },
    disabledTerm() {
      var isDisabled = true;

      if (this.form.cpf.length < 13) {
        this.acceptConsentTerm = false;
        return true;
      }
      if (!this.logged) {
        isDisabled = this.form.name.length < 1;
        if (isDisabled) {
          this.acceptConsentTerm = false;
          return isDisabled;
        }
        isDisabled = this.form.email.length < 1;
        if (isDisabled) {
          this.acceptConsentTerm = false;
          return isDisabled;
        }
      }
      if (
        this.campaignLandingPageConfig.campaign.campaignInputInformation &&
        this.campaignLandingPageConfig.campaign.campaignInputInformation
          .simplifiedCheckoutInformCompany
      ) {
        isDisabled = this.form.companyName.trim().length < 2;
        if (isDisabled) {
          this.acceptConsentTerm = false;
          return isDisabled;
        }
      }
      if (
        this.campaignLandingPageConfig.campaign.campaignInputInformation &&
        this.campaignLandingPageConfig.campaign.campaignInputInformation
          .simplifiedCheckoutInformDepartment
      ) {
        isDisabled = this.form.departamentName.length < 1;
        if (isDisabled) {
          this.acceptConsentTerm = false;
          return isDisabled;
        }
      }
      if (
        this.campaignLandingPageConfig.campaign.campaignInputInformation &&
        this.campaignLandingPageConfig.campaign.campaignInputInformation
          .simplifiedCheckoutInformAddress
      ) {
        isDisabled = 
          this.form.address.postalCode.length < 1 || 
          this.form.address.street.length < 1 || 
          this.form.address.number.length < 1 || 
          this.form.address.neighborhood.length < 1 || 
          this.form.address.city.length < 1;
        if (isDisabled) {
          this.acceptConsentTerm = false;
          return isDisabled;
        }
      }
      if (
        this.campaignLandingPageConfig.campaign.campaignInputInformation &&
        this.campaignLandingPageConfig.campaign.campaignInputInformation
          .simplifiedCheckoutInformGender
      ) {
        isDisabled = this.form.gender.length < 1;
        if (isDisabled) {
          this.acceptConsentTerm = false;
          return isDisabled;
        }
      }
      if (
        this.campaignLandingPageConfig.campaign.campaignInputInformation &&
        this.campaignLandingPageConfig.campaign.campaignInputInformation
          .simplifiedCheckoutInformBirthDate
      ) {
        isDisabled =
          this.CommonHelper.getAge(this.form.birthDate) < 1 ||
          !this.form.birthDate;
        if (isDisabled) {
          this.acceptConsentTerm = false;
          return isDisabled;
        }
      }
      if (
        this.campaignLandingPageConfig.campaign.campaignInputInformation &&
        this.campaignLandingPageConfig.campaign.campaignInputInformation
          .simplifiedCheckoutInformState
      ) {
        isDisabled = this.form.address.state.length < 1;
        if (isDisabled) {
          this.acceptConsentTerm = false;
          return isDisabled;
        }
      }

      if (
        this.form.selectPayment === "pix" ||
        this.form.selectPayment === "barcode"
      ) {
        this.acceptConsentTerm = false;
        isDisabled = false;
        return isDisabled;
      }

      if (this.form.selectPayment === "card") {
        isDisabled = this.form.cardNumber.length < 1;
        if (isDisabled) {
          this.acceptConsentTerm = false;
          return isDisabled;
        }

        isDisabled = this.form.cardName.length < 1;
        if (isDisabled) {
          this.acceptConsentTerm = false;
          return isDisabled;
        }

        isDisabled = this.form.expirationDate.length < 1;
        if (isDisabled) {
          this.acceptConsentTerm = false;
          return isDisabled;
        }

        isDisabled = this.form.cvv.length < 1;
        if (isDisabled) {
          this.acceptConsentTerm = false;
          return isDisabled;
        }
      }

      if (this.form.selectPayment === "accountDebit") {
        isDisabled = this.form.debitAccountInfo.holder.name.length < 1;
        if (isDisabled) {
          this.acceptConsentTerm = false;
          return isDisabled;
        }

        isDisabled =
          this.form.debitAccountInfo.billingAddress.country.length < 1;
        if (isDisabled) {
          this.acceptConsentTerm = false;
          return isDisabled;
        }

        isDisabled = this.form.debitAccountInfo.paymentCompanyCode.length < 1;
        if (isDisabled) {
          this.acceptConsentTerm = false;
          return isDisabled;
        }

        isDisabled = this.form.debitAccountInfo.dayOfMonth.length < 1;
        if (isDisabled) {
          this.acceptConsentTerm = false;
          return isDisabled;
        }

        isDisabled = this.form.debitAccountInfo.bankBranch.length < 1;
        if (isDisabled) {
          this.acceptConsentTerm = false;
          return isDisabled;
        }

        isDisabled = this.form.debitAccountInfo.bankAccount.length < 1;
        if (isDisabled) {
          this.acceptConsentTerm = false;
          return isDisabled;
        }

        isDisabled = this.form.debitAccountInfo.bankAccountDigit.length < 1;
        if (isDisabled) {
          this.acceptConsentTerm = false;
          return isDisabled;
        }
      }

      return isDisabled;
    },
    getPaymentMethodMinimumParcelamentValue() {
      let method = null

      switch (this.form.selectPayment) {
        case "barcode":
          method = this.campaignLandingPageConfig.campaign.campaignPaymentMethodParcelamentList.find(item => {
            return item.paymentMethodId === PaymentMethod.Ticket;
          });
          break;
        case "pix":
          method = this.campaignLandingPageConfig.campaign.campaignPaymentMethodParcelamentList.find(item => {
            return item.paymentMethodId === PaymentMethod.Pix;
          });
          break;
        case "card":
          method = this.campaignLandingPageConfig.campaign.campaignPaymentMethodParcelamentList.find(item => {
            return item.paymentMethodId === PaymentMethod.CreditCard;
          });
          break;
      }
      
      if (method) {
        return method.minimumParcelamentValue;
      } else {
        return 0;
      }
    },
    minimumPeriodDonationValue() {
      let minimumDonationValue = 0;
      let recurrenceSelected = null;
      this.recurrencePeriodList.forEach((recurrence) => {
        if (recurrence.id == this.cart.donationRecurrencePeriodId) {
          recurrenceSelected = recurrence;
          return;
        }
      });
      var resp =
        recurrenceSelected != null &&
          recurrenceSelected.campaignDonationRecurrencePeriods[0]
          ? recurrenceSelected.campaignDonationRecurrencePeriods[0]
            .minimumDonationValue
          : null;

      this.$emit("minimumPeriodDonationValue", resp);
      return resp;
    },
    createNewUser() {
      return !this.logged;
    },
  },
  created() {
    this.taxOwners = TaxOwners;
    this.cart.checkoutRequest = new CheckoutRequest();
    this.cartService = new CartService(null, pagarme);
    this.person = new Person();
    this.routes = Routes;
    this.loadRecurrencePeriods();
    this.locationService.listAllState(this.listAllStateCallback);
    this.paymentMethodList =
    this.campaignLandingPageConfig.campaign.paymentMethodList;
    this.blockButton = this.campaignLandingPageConfig.campaign.campaignPhaseId != this.campaignPhases.EmAndamento;

    this.daysOfMonth = [
      { id: 1, name: 1 },
      { id: 10, name: 10 },
      { id: 20, name: 20 },
    ];
    this.loadRecurrenceDate();
    this.debitAccountBanks = [
      { code: "santander", name: "Santander" },
      { code: "itau", name: "Itaú" },
    ];

    this.ReceiverParcelamentService.listAll().then((res) => {
      if (res) {
        res.forEach((receiverParcelament) => {
          if (
            this.campaignLandingPageConfig.campaign.receiver ==
            receiverParcelament.receiverId
          ) {
            this.receiverParcelament.push(receiverParcelament);
          }
        });
      }
    });

    this.loadQuestions();
  },
  methods: {
    serializeReceiverParcelament(receiverParcelament) {
        var serializedItem = [];
        for (const p in receiverParcelament) {
            const item = receiverParcelament[p];
            for (const r of item.receiverParcelament) {
                serializedItem.push({
                id: r.id,
                receiverId: r.receiverId,
                parcelament: r.parcelament,
                singleTransactionValue: r.singleTransactionValue,
                recurrentTransactionValue: r.recurrentTransactionValue,
                paymentMethodProviderId: PaymentMethodProvider[p]
            });
            }
        }
        return serializedItem;
    },
    selectCity(city) {
      if(city === null) {
        this.form.address.city = '';
        this.form.address.state = '';
      } else {
        this.form.address.city = city.cityName;
        this.form.address.state = city.uf;
      }
      this.validate('address.city')
    },
    loadQuestions() {
      this.checkoutSimplifiedQuestionService.getQuestionsWithOptionsByCampaignId(this.campaignLandingPageConfig.campaignId).then((data) => {
        this.questions = data
      });
    },
    questionOptions(question) {
      if(question.relationWithQuestionId === null) return question.options;
      const questionRelated = this.questions.find(x => x.id === question.relationWithQuestionId);
      const responseQuestionRelated = this.form.cartInformation['response' + questionRelated.id];
      if(responseQuestionRelated === "") return [];
      const optionSelected = questionRelated.options?.find(x => x.description === responseQuestionRelated);
      return question.options?.filter(x => x.relationWithOptionId === optionSelected.id);
    },
    sumTotalCartValue() {
      this.totalCartValue = 0;
      this.cart.cartItems.forEach((cartItem) => {
        this.totalCartValue += parseFloat(
          String(cartItem.itemValue).replace(/\./g, "").replace(/\,/g, ".")
        );
      });
      this.cartTaxMethodPayment(this.totalCartValue);

      return this.totalCartValue;
    },
    gethowMany() {
      this.listHowManyOptions = [];
      var parcelamentValue = 0;
      var parcelamentValueNotFormated = 0;
      var tax = 0;
      var parcelament = this.parcelamentMethod != null ? this.parcelamentMethod.parcelament : this.campaignLandingPageConfig.campaign.creditCardParcelament;
      var minimumParcelamentValue = this.parcelamentMethod != null ? this.parcelamentMethod.minimumParcelamentValue : this.campaignLandingPageConfig.campaign.minimumParcelamentValue;
      this.gethowManyInput += 1;

      if (parseInt(this.cart.donationRecurrencePeriodId) > 1)
        return this.listHowManyOptions;
      for (
        let index = 0;
        index < parcelament;
        index++
      ) {
        var parcelamentObject = JSON.parse(JSON.stringify(this.howMany[index]));

        if (
          this.receiverParcelament.length > 0
        ) {
          if (
            this.campaignLandingPageConfig.campaign.taxOwner ==
            TaxOwners.Company
          ) {
            parcelamentValue = parcelamentValueNotFormated =
              this.totalCartValue / parcelamentObject.quantidade;
          } else {
            if (this.form.selectPayment == "card") {
              tax =
                this.totalCartValue /
                ((100 -
                  this.receiverParcelament.find(
                    (x) => x.parcelament == parcelamentObject.quantidade
                  ).singleTransactionValue) /
                  100) -
                this.totalCartValue;
              parcelamentValue = parcelamentValueNotFormated =
                (this.totalCartValue + tax) / parcelamentObject.quantidade;
            }
            if (this.form.selectPayment == 'barcode') {
              tax = this.configManager.taxBoleto;
              parcelamentValue = parcelamentValueNotFormated =
                this.totalCartValue / parcelamentObject.quantidade + tax;
            }
            if (this.form.selectPayment == "pix") {
              tax = this.getPixTaxParcelamentValue(parcelamentObject.quantidade);
              parcelamentValue = parcelamentValueNotFormated =
                (this.totalCartValue / parcelamentObject.quantidade) + tax;
            }
          }

          parcelamentValue = this.formatterHelper.formatMoney(parcelamentValue);

          var totalParcelamentValue =
            parseFloat(parcelamentValue.replace(".", "").replace(",", ".")) *
            parcelamentObject.quantidade;
          totalParcelamentValue = this.formatterHelper.formatMoney(
            totalParcelamentValue
          );

          if ((parcelamentValueNotFormated * parcelamentObject.quantidade) < this.campaignLandingPageConfig.campaign.minimumDonationValue
            || this.totalCartValue < this.campaignLandingPageConfig.campaign.minimumDonationValue) {
            break;
          }

          if (
            parcelamentValueNotFormated <
            minimumParcelamentValue
          ) {
            break;
          }

          parcelamentObject.name =
            this.howMany[index].name +
            " " +
            parcelamentValue.toString() +
            " __________ " +
            "Valor total: " +
            totalParcelamentValue;
        }

        this.listHowManyOptions.push(parcelamentObject);
      }
      return this.listHowManyOptions;
    },
    showInputPaymentDay() {
      if ((parseInt(this.cart.donationRecurrencePeriodId) > 1 || this.cart.creditCardParcelament > 1) && this.cart.paymentMethodId != 2)
        return true;
      return false;
    },
    setParcelamentMethod(payment) {
      if (this.campaignLandingPageConfig.campaign.campaignPaymentMethodParcelamentList
        && this.campaignLandingPageConfig.campaign.campaignPaymentMethodParcelamentList.find(x => x.paymentMethodId == payment)) {
        this.parcelamentMethod = this.campaignLandingPageConfig.campaign.campaignPaymentMethodParcelamentList.find(x => x.paymentMethodId == payment);
      }

    },
    verifyParcelamentPaymentMethod() {
      var campaignPaymentMethodParcelamentList = this.campaignLandingPageConfig.campaign.campaignPaymentMethodParcelamentList;
      if (campaignPaymentMethodParcelamentList && campaignPaymentMethodParcelamentList.length > 0) {
        for (let index = 0; index < campaignPaymentMethodParcelamentList.length; index++) {
          const element = campaignPaymentMethodParcelamentList[index];
          if (element.paymentMethodId == this.paymentMethod.CreditCard && this.form.selectPayment == 'card') {
            return element.parcelament > 1;
          }
          if (element.paymentMethodId == this.paymentMethod.Ticket && this.form.selectPayment == 'barcode') {
            return (element.parcelament > 1 || (parseInt(this.cart.donationRecurrencePeriodId) > 1));
          }
          if (element.paymentMethodId == this.paymentMethod.Pix && this.form.selectPayment == 'pix') {
            return (element.parcelament > 1 || (parseInt(this.cart.donationRecurrencePeriodId) > 1));
          }
        }
      }

      return this.campaignLandingPageConfig.campaign.creditCardParcelament > 2 &&
        (this.form.selectPayment == 'barcode' || this.form.selectPayment == 'pix');
    },
    getPixTaxParcelamentValue(parcelamentNumber) {
      var parcelamentValue = this.totalCartValue / parcelamentNumber;
      var tax = parcelamentValue / ((100 - this.configManager.taxPix) / 100) - parcelamentValue;
      tax = tax <= 5.51 ? tax : 5.51;
      return tax;
    },
    getDayOfMonthInput() {
      this.dayOfMonthInput += 1;
    },
    resetCreditCardParcelament() {
      this.cart.creditCardParcelament = 1;
      this.gethowManyInput += 1;
    },
    verifydonationRecurrencePeriod() {
      this.donationRecurrencePeriod = this.cart.donationRecurrencePeriodId;
    },
    findPersonByCodeIndication() {
      this.personIndicationName = "";
      this.personService
        .findPersonByCodeIndication(this.form.memberCode)
        .then((data) => {
          this.personIndicationName = "Indicação: " + data;
        });
    },
    listAllStateCallback(data) {
      this.stateItems = data;
    },
    searchCEP() {
      if (this.form.address.postalCode.length == 8) {
        this.$store.commit("SET_LOADER", true);
        this.locationService
          .searchCEP(this.form.address.postalCode)
          .then(data => {
            this.searchCEPCallback(data);
          })
          .catch((error) => {
            console.log(error);
            this.$store.commit("SET_LOADER", false);
          });
      }
    },
    searchCEPCallback(obj) {
      if (obj && !obj.erro) {
        this.form.address.street = obj.street;
        this.form.address.neighborhood = obj.neighborhood;
        this.form.address.city = obj.city;
        this.form.address.state = obj.state;
        // this.cepValidation = true;
        // this.addressDisable.city = true;
        // this.addressDisable.state = true;
      } else {
        this.form.address.street = "";
        this.form.address.neighborhood = "";
        this.form.address.city = "";
        this.form.address.state = "";
        // this.cepValidation = false;
        // this.addressDisable.city = false;
        // this.addressDisable.state = false;
      }
      this.$store.commit("SET_LOADER", false);
    },
    validateForm() {
      const fields = ['cpf']

      if (this.form.selectPayment === 'card') {
        fields.push('cardNumber', 'cardName', 'expirationDate', 'cvv')
      }

      if (this.form.selectPayment === 'accountDebit') {
        fields.push('holderName', 'paymentCompanyCode', 'dayOfMonth', 'bankBranch', 'bankAccount', 'bankAccountDigit')
      }

      if(!this.logged) {
        fields.push('name', 'email')
      }

      if(this.questions.length > 0) {
        this.questions.forEach(question => {
          fields.push('cartInformation.response' + question.responseNumber)
        })
      }

      if (this.campaignLandingPageConfig.campaign.campaignInputInformation) {
        if (this.campaignLandingPageConfig.campaign.campaignInputInformation.simplifiedCheckoutInformCompany) {
          fields.push('companyName')
        }
        if (this.campaignLandingPageConfig.campaign.campaignInputInformation.simplifiedCheckoutInformDepartment) {
          fields.push('departamentName')
        }
        if (this.campaignLandingPageConfig.campaign.campaignInputInformation.simplifiedCheckoutInformAddress) {
          fields.push('address.postalCode', 'address.street', 'address.number', 'address.neighborhood', 'address.city')
        }
        if(this.campaignLandingPageConfig.campaign.campaignInputInformation.simplifiedCheckoutInformState) {
          fields.push('address.state')
        }
        if (this.campaignLandingPageConfig.campaign.campaignInputInformation.simplifiedCheckoutInformGender) {
          fields.push('gender')
        }
        if (this.campaignLandingPageConfig.campaign.campaignInputInformation.simplifiedCheckoutInformBirthDate) {
          fields.push('birthDate')
        }
        if(this.campaignLandingPageConfig.campaign.campaignInputInformation.simplifiedCheckoutInformPhone){
          fields.push('phone')
        }
        if (this.campaignLandingPageConfig.campaign.campaignInputInformation.simplifiedCheckoutInformCityWithState) {
          fields.push('address.city')
        }        
      }
      let isValid = true;
      fields.forEach(field => {
        isValid = this.validate(field) == false ? false : isValid
      })
      return isValid;
    },
    validate(property) {
      let isValid = false;
      if(property.includes('.')) {
        const [parent, child] = property.split('.')
        isValid = this.form[parent][child].trim().length > 0
        property = child
      } else {
        isValid = this.form[property].trim().length > 0;
      }

      if (property == "cpf") {
        var cpf = this.form.cpf
          .replace(".", "")
          .replace(".", "")
          .replace("-", "")
          .replace("/", "");
        isValid =
          cpf.length == 11
            ? this.CommonHelper.validateCPF(cpf)
            : this.CommonHelper.validateCNPJ(cpf);
      }

      if (property == "email") {
        isValid = this.CommonHelper.validateEmail(this.form[property]);
      }

      if (property == "birthDate") {
        isValid = this.CommonHelper.getAge(this.form.birthDate) > 1;
      }
      
      let el = null
      if(Array.isArray(this.$refs[property + "Input"])) {
        el = this.$refs[property + "Input"][0]
      } else {
        el = this.$refs[property + "Input"]
      }
      if (isValid) el.classList.remove("has-error");
      else el.classList.add("has-error");
      return isValid;
    },
    loadRecurrencePeriods() {
      this.campaignService.listAllDonationRecurrencePeriodByCampaign((data) => {
        this.recurrencePeriodList = data;
        for (const recurrency of this.recurrencePeriodList) {
          if (this.recurrencePeriodList.length == 1) {
            this.cart.donationRecurrencePeriodId = recurrency.id;
            this.mainRecurrencyToSelect = recurrency.name
          }
          else if (this.mainRecurrencyToSelect !== null && recurrency.name === this.mainRecurrencyToSelect) this.cart.donationRecurrencePeriodId = recurrency.id
          else if (this.mainRecurrencyToSelect === null && this.recurrencePeriodList.length == 2) this.cart.donationRecurrencePeriodId = this.recurrencePeriodList[1].id;
          else if (this.mainRecurrencyToSelect === null && this.recurrencePeriodList.length !== 2) this.cart.donationRecurrencePeriodId = this.recurrencePeriodList[0].id;
          else this.cart.donationRecurrencePeriodId = this.recurrencePeriodList[0].id
        }

        if(this.campaignLandingPageConfig.campaignId === 656) {
          this.cart.donationRecurrencePeriodId = null
          this.mainRecurrencyToSelect = ''
        }
      });
    },
    changePayment(payment) {
      this.resetCreditCardParcelament();
      if (payment == "card") {
        this.cart.paymentMethodId = PaymentMethod.CreditCard;
        //this.cart.donationRecurrencePeriodId = 1;
        this.changeProvidersAndTax(PaymentMethod.CreditCard);
        this.setParcelamentMethod(PaymentMethod.CreditCard);

      } else if (payment == "barcode") {
        this.cart.paymentMethodId = PaymentMethod.Ticket;
        this.changeProvidersAndTax(PaymentMethod.Ticket);
        //this.cart.donationRecurrencePeriodId = 1;
        this.setParcelamentMethod(PaymentMethod.Ticket);

      } else if (payment == "pix") {
        this.changeProvidersAndTax(PaymentMethod.Pix);
        this.cart.paymentMethodId = PaymentMethod.Pix;
        //this.cart.donationRecurrencePeriodId = 1;
        this.setParcelamentMethod(PaymentMethod.Pix);

      } else if (payment == "accountDebit") {
        this.changeProvidersAndTax(PaymentMethod.AccountDebit);
        this.cart.paymentMethodId = PaymentMethod.AccountDebit;
        this.setParcelamentMethod(PaymentMethod.AccountDebit);

      }
      this.form.selectPayment = payment;
    },
    getCardFlag() {
      var cardnumber = this.form.cardNumber.replace(/[^0-9]+/g, "");

      var cards = {
        amex: /^3[47][0-9]{13}/,
        aura: /^(5078\d{2})(\d{2})(\d{11})$/,
        //banes
        diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
        discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
        elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
        //hiper
        hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
        jcb: /^(?:2131|1800|35\d{3})\d{11}/,
        mastercard: /^5[1-5][0-9]{14}/,
        visa: /^4[0-9]{12}(?:[0-9]{3})/,
      };

      for (var flag in cards) {
        if (cards[flag].test(cardnumber)) {
          this.flag = flag;
          return false;
        } else {
          this.flag = "";
        }
      }

      return false;
    },
    nameToUpperCase() {
      if (this.form && this.form.cardName)
        this.form.cardName = this.form.cardName
          .replace(/[0-9]+/i, "")
          .toUpperCase();
      if (this.form && this.form.name)
        this.form.name = this.form.name.replace(/[0-9]+/i, "").toUpperCase();
      if (this.form && this.form.socialReason)
        this.form.socialReason = this.form.socialReason
          .replace(/[0-9]+/i, "")
          .toUpperCase();
    },
    clearNumber() {
      this.form.address.number = this.form.address.number.replace(/[^0-9]+/g, "");
    },
    openModalConsentTerm() {
      if (!this.disabledTerm) {
        this.setInfoInCart();
        this.showConsentTerm = true;
        this.$refs.consertTermComponent.open();
      }
    },
    setInfoInCart() {
      //Credit Card Info
      this.cart.checkoutRequest.creditCardInfo.cardNumber =
        this.form.cardNumber;
      this.cart.checkoutRequest.creditCardInfo.holder.name = this.form.cardName;
      this.cart.checkoutRequest.creditCardInfo.expirationMonth =
        this.form.expirationDate.substring(0, 2);
      this.cart.checkoutRequest.creditCardInfo.expirationYear =
        "20" + this.form.expirationDate.substring(3, 5);
      this.cart.checkoutRequest.creditCardInfo.cvv = this.form.cvv;
      this.cart.checkoutRequest.creditCardInfo.holder.cpf = this.form.cpf;

      this.cart.paymentMethodProviderId =
        this.campaignLandingPageConfig.campaign.campaignPaymentMethodProvider.creditCardPaymentMethodProviderId;
      this.cart.campaign = this.campaignLandingPageConfig.campaign;
      this.cart.campaignId = this.campaignLandingPageConfig.campaignId;
      this.cart.totalValue = this.totalCartValue;
      this.cartItem.project = this.project;

      //Debit Account info
      this.cart.checkoutRequest.debitAccountInfo.holder.name =
        this.form.debitAccountInfo.holder.name;
      this.cart.checkoutRequest.debitAccountInfo.holder.CPF = this.form.cpf;
      this.cart.checkoutRequest.debitAccountInfo.paymentCompanyCode =
        this.form.debitAccountInfo.paymentCompanyCode;
      this.cart.checkoutRequest.debitAccountInfo.dayOfMonth =
        this.form.debitAccountInfo.dayOfMonth;
      this.cart.checkoutRequest.debitAccountInfo.bankBranch =
        this.form.debitAccountInfo.bankBranch;
      this.cart.checkoutRequest.debitAccountInfo.bankAccount =
        this.form.debitAccountInfo.bankAccount;
      this.cart.checkoutRequest.debitAccountInfo.bankAccountDigit =
        this.form.debitAccountInfo.bankAccountDigit;
      this.cart.checkoutRequest.creditCardInfo.billingAddress.country =
        this.form.debitAccountInfo.billingAddress.country;

      //Ticket Info
      this.cart.checkoutRequest.ticketInfo = this.form.ticketInfo;

      //Pix Info
      this.form.pixInfo = { dayOfMonth: this.form.ticketInfo.dayOfMonth };
      this.cart.checkoutRequest.pixInfo = this.form.pixInfo;

      // Cart Information Info
      this.cart.checkoutRequest.cartInformation = this.form.cartInformation;

      this.cart.checkoutRequest.tokenRecaptcha = this.tokenRecaptcha;

      if (this.cart.cartItems[0].itemValue <= 0) {
        this.CommonHelper.swal("Selecione um valor para doação");
        return;
      }

      let cpfOrCnpj = this.form.cpf.replace(/[^0-9]+/g, "");
      const cpf = cpfOrCnpj.length > 11 ? null : cpfOrCnpj;
      const cnpj = cpfOrCnpj.length == 11 ? null : cpfOrCnpj;

      this.cartItem.projectId = this.project.id;
      this.cart.CreditCardCartDetails = new CreditCardCartDetails();
      this.person.name = this.form.name;
      this.person.email = this.form.email;
      this.person.cpf = cpf;
      this.person.isEmployee = false;
      this.person.isEmployeeWithCorporativeEmail = false;
      this.person.companyName = this.form.companyName;
      this.person.departamentName = this.form.departamentName;
      this.person.phone = this.form.phone;
      this.person.address = this.form.address;
      this.person.gender = this.form.gender;
      this.person.birthDate = this.form.birthDate;
      this.person.registrationWithMemberCode = this.form.memberCode;
      this.person.socialReason = this.form.socialReason;
      this.person.cnpj = cnpj;
    },
    sendPurshase(cart) {
      window.dataLayer = window.dataLayer || [];
      const methods = {
        2: "Cartão de crédito",
        6: "Pix",
        3: "Boleto",
        4: "Débito em conta"
      }
      const purshase = {
        event: 'purchase',
        ecommerce: {
          value: cart.cartItems.reduce((accumulator, cartItem) => accumulator + cartItem.itemValue, 0),
          currency: 'BRL',
          transaction_id: cart.id,
          items: cart.cartItems.map(x => {
            return {
              item_name: methods[cart.paymentMethodId],
              item_id: cart.donationRecurrencePeriodId,
              price: x.itemValue,
              quantity: 1
            }
          })
        },
      }
      console.log("purshase", purshase)
      window.dataLayer.push(purshase)
    },
    sendCreditCardCheckout() {
      this.cartService
        .creditCardCheckout(this.cart)
        .then((cart) => {
          if (cart.statusId == CartStatus.PaymentError)
            throw "Não foi possível efetuar a operação, verifique junto a seu banco ou operadora de cartão se há algum problema.";

          return cart;
        })
        .then((data) => {
          this.sendPurshase(data);
          this.redirectToCongratsPage(data);
        })
        .catch((err) => {
          if (err.errorMessage) CommonHelper.swal(err.errorMessage);
          else CommonHelper.swal(err);

          console.log(err);
        });
    },
    sendTicketCheckout() {
      this.cartService.ticketCheckout(this.cart).then((data) => {
        this.sendPurshase(data.cart)
        this.redirectToCongratsPage(data.cart, true);
      });
    },
    sendAccountDebitCheckout() {
      this.cartService.debitAccountCheckout(this.cart).then((data) => {
        this.sendPurshase(data)
        this.redirectToCongratsPage(data);
      });
    },
    sendPixCheckout() {
      //this.cart.totalValue = this.cartTotalPrice;
      this.cartService.checkout(this.cart).then((data) => {
        this.finishedCart = data;
        this.sendPurshase(data)
        this.$refs.modalPix.open(data);
      });
    },
    finishPix() {
      this.redirectToCongratsPage(this.finishedCart);
    },
    async checkout() {
      if(!this.validateForm()) {
        CommonHelper.swal(
          "Preencha todos os campos obrigatórios"
        );
        return
      }
      let key = process.env.VUE_APP_GOOGLE_KEY_RECAPTCHA_V3;
      this.tokenRecaptcha = await grecaptcha.execute(key, { action: "submit" });

      this.cart.totalValue = this.totalCartValue;

      if (this.cartItem.itemValue < this.minimumPeriodDonationValue) {
        this.CommonHelper.swal(
          "Desculpe, você está doando um valor inferior ao mínimo permitido nessa campanha"
        );
        return;
      }

      if (!this.acceptConsentTerm && !this.disabledTerm) {
        CommonHelper.swal(
          "É preciso aceitar os termos de anuência para prosseguir"
        );
        return;
      }

      if (this.disabledTerm) {
        CommonHelper.swal(
          "Verifique se todos os campos obrigatórios foram preenchidos"
        );
        return;
      }

      if (this.cart.totalValue <= 0 || this.cart.totalValue === null) {
        this.CommonHelper.swal("Selecione um valor para doação");
        return;
      }

      if (this.showInputPaymentDay() && !this.form.ticketInfo.dayOfMonth && !this.form.debitAccountInfo.dayOfMonth) {
        this.CommonHelper.swal("Selecione uma data de vencimento");
        return;
      }

      this.setInfoInCart();

      if (this.createNewUser) return this.createPerson();
      if (this.form.companyName) {
        const self = this;

        await new Promise((resolve) => {
          this.personService.getProfile(function (person) {
            person.companyName = self.form.companyName;
            return self.personService.saveProfile(resolve, person);
          });
        });
      }

      switch (this.cart.paymentMethodId) {
        case PaymentMethod.CreditCard:
          this.sendCreditCardCheckout();
          break;
        case PaymentMethod.Ticket:
          this.sendTicketCheckout();
          break;
        case PaymentMethod.AccountDebit:
          this.sendAccountDebitCheckout();
          break;
        case PaymentMethod.Pix:
          this.sendPixCheckout();
          break;
        default:
          break;
      }
    },
    redirectToCongratsPage(cart, redirectToTicket = false) {
      if (redirectToTicket) {
        let routeData = this.$router.resolve({
          name: Routes.web.GenerateTicket,
          query: { cartId: cart.id, again: true },
        });
        window.open(routeData.href, "_blank");
      }
      this.$router.push({
        name: Routes.app.Congrats,
        query: { id: cart.id },
      });
    },
    changeProvidersAndTax(paymentMethod) {
      switch (paymentMethod) {
        case PaymentMethod.CreditCard:
          this.providerId =
            this.campaignLandingPageConfig.campaign.campaignPaymentMethodProvider.creditCardPaymentMethodProviderId;
          this.configManager =
            this.campaignLandingPageConfig.campaign.campaignPaymentMethodProvider.creditCardPaymentMethodProvider.configManager;
          break;
        case PaymentMethod.Ticket:
          this.providerId =
            this.campaignLandingPageConfig.campaign.campaignPaymentMethodProvider.ticketPaymentMethodProviderId;
          this.configManager =
            this.campaignLandingPageConfig.campaign.campaignPaymentMethodProvider.ticketPaymentMethodProvider.configManager;
          break;
        case PaymentMethod.AccountDebit:
          this.providerId =
            this.campaignLandingPageConfig.campaign.campaignPaymentMethodProvider.accountDebitPaymentMethodProviderId;
          this.configManager =
            this.campaignLandingPageConfig.campaign.campaignPaymentMethodProvider.accountDebitPaymentMethodProvider.configManager;
          break;
        case PaymentMethod.Pix:
          this.providerId =
            this.campaignLandingPageConfig.campaign.campaignPaymentMethodProvider.pixPaymentMethodProviderId;
          this.configManager =
            this.campaignLandingPageConfig.campaign.campaignPaymentMethodProvider.pixPaymentMethodProvider.configManager;
          break;
        default:
          break;
      }
    },
    cartTaxMethodPayment() {
      let totalPrice = this.totalCartValue;
      let tax = 0;
      let taxOwner = 0;

      switch (this.cart.paymentMethodId) {
        case PaymentMethod.Ticket:
          taxOwner = this.campaignLandingPageConfig.campaign.taxOwnerTicket
          break;
        case PaymentMethod.CreditCard:
          taxOwner = this.campaignLandingPageConfig.campaign.taxOwnerCreditCard
          break;
        case PaymentMethod.Pix:
          taxOwner = this.campaignLandingPageConfig.campaign.taxOwnerPix
          break;
        default:
          break;
      }
      this.campaignLandingPageConfig.campaign.taxOwner = taxOwner

      if (
        totalPrice == 0 ||
        taxOwner == TaxOwners.Company
      ) {
        this.cart.taxValue = 0;
        return 0;
      }

      if (this.cart.paymentMethodId == PaymentMethod.Ticket) {
        tax = this.configManager.taxBoleto;
      } else if (
        this.cart.paymentMethodId == PaymentMethod.CreditCard &&
        (this.cart.donationRecurrencePeriodId == null ||
          this.cart.donationRecurrencePeriodId ==
          DonationRecurrencePeriod.Single)
      ) {
        var parcelament =
          !this.cart.creditCardParcelament ||
            this.cart.creditCardParcelament == 0
            ? 1
            : this.cart.creditCardParcelament;
        tax =
          totalPrice /
          ((100 -
            this.receiverParcelament.find((x) => x.parcelament == parcelament)
              .singleTransactionValue) /
            100) -
          totalPrice;
      } else if (
        this.cart.paymentMethodId == PaymentMethod.CreditCard &&
        this.cart.donationRecurrencePeriodId > DonationRecurrencePeriod.Single
      ) {
        tax =
          totalPrice /
          ((100 -
            this.receiverParcelament.find((x) => x.parcelament == 1)
              .singleTransactionValue) /
            100) -
          totalPrice;
      } else if (
        this.cart.paymentMethodId == PaymentMethod.AccountDebit &&
        (this.cart.donationRecurrencePeriodId == null ||
          this.cart.donationRecurrencePeriodId ==
          DonationRecurrencePeriod.Single)
      ) {
        tax =
          totalPrice / ((100 - this.configManager.taxAccountDebit) / 100) -
          totalPrice;
      } else if (
        this.cart.paymentMethodId == PaymentMethod.AccountDebit &&
        this.cart.donationRecurrencePeriodId > DonationRecurrencePeriod.Single
      ) {
        tax =
          totalPrice /
          ((100 - this.configManager.taxRecurrentAccountDebit) / 100) -
          totalPrice;
      } else if (this.cart.paymentMethodId == PaymentMethod.Pix) {
        tax =
          totalPrice /
          ((100 - this.configManager.taxPix) / 100) -
          totalPrice;
        tax = tax <= 5.51 ? tax : 5.51;
        if (this.cart.creditCardParcelament > 1) {
          tax = this.getPixTaxParcelamentValue(this.cart.creditCardParcelament)
          this.cart.taxValue = tax * this.cart.creditCardParcelament
          return tax;
        }
      }

      this.cart.taxValue = tax;

      return tax;
    },
    createPerson() {
      if (this.person == null) {
        this.person = new Person();
        this.setInfoInCart();
      }

      this.personService
        .getOrCreateBySimplifiedDonation(this.person)
        .then((data) => {
          if (data.isNewPersonBySimplifiedDonation) {
            this.userService.sendCreatePassword(data.email);
          }
          this.isAnonymous = false;
          this.cart.user = data.user;
          this.cart.userId = data.user.id;

          switch (this.cart.paymentMethodId) {
            case PaymentMethod.CreditCard:
              this.sendCreditCardCheckout();
              break;
            case PaymentMethod.Ticket:
              this.sendTicketCheckout();
              break;
            case PaymentMethod.AccountDebit:
              this.sendAccountDebitCheckout();
              break;
            case PaymentMethod.Pix:
              this.sendPixCheckout();
              break;
            default:
              break;
          }
        });
    },
    loadRecurrenceDate() {
      if (this.daysOfMonth.length > 0 && this.daysOfMonth.length > 2) this.form.ticketInfo.dayOfMonth = this.daysOfMonth[1].name
      else if (this.daysOfMonth.length > 0 && this.daysOfMonth.length < 2) this.form.ticketInfo.dayOfMonth = this.daysOfMonth[0].name
    }
  },
  mounted() {
    this.cart.cartItems = [this.cartItem];
    const key = process.env.VUE_APP_GOOGLE_KEY_RECAPTCHA_V3;
    var script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${key}`;
    document.head.appendChild(script);
    this.cart.donationRecurrencePeriodId = this.$refs.recurrencePeriodInput.value
  },
};
</script>
<style>
.widget-form-group select {
  font-weight: normal;
  font-size: 14px;
  color: #4c4d4f;
  background-position: calc(100% - 16px);
  background-repeat: no-repeat;
  appearance: none;
  -webkit-appearance: none;
  padding-right: 32px;
  background-image: url('data:image/svg+xml;utf8,<svg width="13" height="8" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.21967 0.945408C0.512563 0.652515 0.987437 0.652515 1.28033 0.945408L8 7.66508L14.7197 0.945408C15.0126 0.652515 15.4874 0.652515 15.7803 0.945408C16.0732 1.2383 16.0732 1.71318 15.7803 2.00607L8.53033 9.25607C8.23744 9.54896 7.76256 9.54896 7.46967 9.25607L0.21967 2.00607C-0.0732233 1.71318 -0.0732233 1.2383 0.21967 0.945408Z" fill="%2380849A"/></svg>');
}

.widget-form-control input {
  font-weight: normal;
}

.widget-form-group .input-comp {
  display: block;
}

.widget-form-group .inside-input {
  width: 100%;
  height: 100%;
}

.widget-form-group .inside-input input {
  width: 100%;
  height: 100%;
  padding: 0;
}
</style>